import React, { useState, useEffect } from 'react';
import logo from './img/logo_ati.png';
import './App.css';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    content: '',
    user_id: '',
    user_pw: '',
  });
  
  const [posts, setPosts] = useState([]); // 글 목록을 저장할 상태
  const [pageNum, setPageNum] = useState(1);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 글 목록을 불러오는 함수
  const fetchPosts = async (page) => {
    try {
      const response = await fetch(`https://www.atimania.com/api/post?page=${page}&limit=20`);
      const data = await response.json();
      if (Array.isArray(data)) // 240924. 배열인 경우에만 글 목록에 추가
        setPosts((prevPosts) => [...prevPosts, ...data]);
      //setPosts((prevPosts) => [...prevPosts, ...data]); // 이전 글 목록에 새로 받아온 글을 추가
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  // 컴포넌트가 처음 렌더링될 때 글 목록을 불러옴
  useEffect(() => {
    fetchPosts(pageNum);
  }, [pageNum]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let ip = '';
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      ip = data.ip;
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
    
    const language = navigator.language || navigator.languages[0];

    const postData = {
      content: formData.content,
      ip,
      language,
      category_id: 1,
      user_id: formData.user_id,
      user_pw: formData.user_pw,
    };

    try {
      const response = await fetch('https://www.atimania.com/api/post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        alert('글이 성공적으로 작성되었습니다.');
        setModalOpen(false);
        setPageNum(1);  // 글을 다시 불러오기 위해 페이지를 초기화
        setPosts([]);   // 기존 글 목록을 비움
        fetchPosts(1);  // 첫 페이지 글을 다시 불러옴
      } else {
        alert('글 작성에 실패했습니다.');
      }
    } catch (error) {
      console.error('Error submitting post:', error);
    }
  };

  return (
    <div style={{ height: '100vh', backgroundColor: '#999999' }}>
      <header>
        <img src={logo} alt="logo" />
        <div style={{
          width: '100px',
          padding: '5px',
          boxSizing: 'border-box',
          backgroundColor: '#666666',
          color: 'white',
          fontSize: '13px'
        }}>
          공사중 ...
        </div>
        <div style={{
          width: '100px',
          height: '3px',
          backgroundColor: '#0098c9'
        }} />
        <button onClick={() => setModalOpen(true)}>글쓰기</button>
      </header>

      {/* 글 목록을 렌더링 */}
      <div>
        {posts.map((post, index) => (
          <div key={index} style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
            <strong>{post.user_id}</strong>: {post.content}
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <form onSubmit={handleSubmit}>
            <div style={{ padding: '5px' }}>
              <label>
                사용자명&nbsp;&nbsp;
                <input
                  type="text"
                  name="user_id"
                  value={formData.user_id}
                  onChange={handleInputChange}
                  required
                  maxLength="20"
                  style={{
                    fontSize: '12px'
                  }}
                />
              </label>
            </div>
            <div style={{ padding: '5px' }}>
              <label>
                비밀번호&nbsp;&nbsp;
                <input
                  type="password"
                  name="user_pw"
                  value={formData.user_pw}
                  onChange={handleInputChange}
                  required
                  style={{
                    fontSize: '12px'
                  }}
                />
              </label>
            </div>
            <div style={{ padding: '5px' }}>
              <label>
                내용&nbsp;&nbsp;
                <input
                  type="text"
                  name="content"
                  value={formData.content}
                  onChange={handleInputChange}
                  required
                  maxLength="50"
                  style={{
                    width: '250px',
                    fontSize: '12px'
                  }}
                />
              </label>
            </div>
            <div style={{ padding: '10px', textAlign: 'center' }}>
              <button type="submit">전송</button>&nbsp;&nbsp;
              <button type="button" onClick={() => setModalOpen(false)}>취소</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;
